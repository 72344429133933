<template>
  <div
    class="certifications-hero-container"
    :style="
      `background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(82, 94, 110, 0.75)), url(${backgroundImageUrl});`
    "
  >
    <div class="container">
      <div class="certifications-body">
        <h1>{{ certificationsHero.title }}</h1>

        <div class="location-badges">
          <div
            v-for="location in certificationsHero.locations"
            :key="location.id"
            class="location-badge"
          >
            <router-link :to="{ path: location.uri }">
              <img
                :src="location.image[0].url"
                :alt="location.image[0].title"
              />
              <span>{{ location.title }}</span>
            </router-link>
          </div>
        </div>

        <h3 v-if="certificationsHero.partners.length">PARTNER FACILITIES</h3>

        <div class="partner-badges">
          <div
            v-for="partner in certificationsHero.partners"
            :key="partner.id"
            class="partner-badge"
          >
            <a :href="partner.anchorLink" target="_blank">
              <img :src="partner.logo[0].url" :alt="partner.logo[0].title" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CertificationsHeroContainer',
  props: {
    certificationsHero: {
      type: Object,
      required: true
    }
  },
  computed: {
    backgroundImageUrl() {
      return this.certificationsHero
        ? this.certificationsHero.backgroundImage[0].url
        : ''
    }
  },
  watch: {
    backgroundImageUrl(newVal) {
      if (newVal.length) {
        this.preloadImage()
      }
    }
  },
  mounted() {
    this.preloadImage()
  },
  methods: {
    preloadImage() {
      if (!this.backgroundImageUrl.length) return

      const preloader = document.createElement('img')

      preloader.addEventListener('load', () => {
        this.$emit('loaded')
      })

      preloader.src = this.backgroundImageUrl
    }
  }
}
</script>

<style lang="scss" scoped>
.certifications-hero-container {
  @apply bg-cover;
  @apply bg-no-repeat;
  @apply bg-center;
  min-height: 120vh;

  .container {
    .certifications-body {
      padding-top: 80px;

      h1 {
        @apply uppercase text-white mb-12;
        font-size: 28px;
        letter-spacing: 2px;
        text-shadow: 0 0 15px rgba(60, 60, 60, 0.3);
      }

      .location-badges {
        @apply flex flex-wrap justify-evenly;
        margin-top: 80px;
        margin-bottom: 80px;

        .location-badge {
          @apply w-1/2 mb-8;

          &:hover {
            img,
            span {
              transform: scale(1.1);
            }
          }

          img {
            @apply w-2/3 mx-auto;
            transition: all 0.3s ease;
            margin-bottom: 24px;
            filter: drop-shadow(0 0 10px rgba(60, 60, 60, 0.7));
          }

          span {
            @apply block w-4/5 mx-auto text-white text-center uppercase;
            font-family: 'FuturaPtMedium', serif;
            font-size: 20px;
            letter-spacing: 2px;
            transition: all 0.3s ease;
          }
        }
      }

      h3 {
        @apply inline-block uppercase text-white mt-24;
        border-bottom: 4px solid white;
        font-size: 24px;
        line-height: 1em;
        letter-spacing: 2px;
        text-shadow: 0 0 15px rgba(60, 60, 60, 0.3);
      }

      .partner-badges {
        @apply flex flex-wrap justify-center;
        padding-top: 40px;
        padding-bottom: 120px;

        .partner-badge {
          @apply w-3/4 mx-auto py-8;

          &:hover {
            img {
              transform: scale(1.1);
            }
          }

          img {
            transition: all 0.3s ease;
            filter: drop-shadow(0 0 10px rgba(60, 60, 60, 0.7));
          }
        }
      }
    }
  }

  /////////////////////////////////////////////

  @media screen and (min-width: 600px) {
    .container {
      .certifications-body {
        //padding-top: 120px;

        h1 {
          font-size: 48px;
        }

        .location-badges {
          .location-badge {
            &:nth-of-type(odd) {
              img {
                @apply ml-auto mr-12;
              }
              span {
                @apply ml-auto mr-0;
              }
            }
            &:nth-of-type(even) {
              img {
                @apply ml-12 mr-auto;
              }
              span {
                @apply mr-auto ml-0;
              }
            }

            img {
              @apply w-1/3;
            }

            span {
              @apply w-3/5;
              font-size: 20px;
            }
          }
        }

        h3 {
          font-size: 42px;
        }

        .partner-badges {
          @apply flex-no-wrap;

          .partner-badge {
            @apply w-1/3 mx-12;
          }
        }
      }
    }
  }

  /////////////////////////////////////////////

  @media screen and (min-width: 900px) {
    .container {
      .certifications-body {
        //padding-top: 160px;
        h1 {
          font-size: 68px;
        }

        .location-badges {
          @apply flex-no-wrap;
          .location-badge {
            @apply w-3/12 mb-0;

            &:nth-of-type(even),
            &:nth-of-type(odd) {
              img {
                @apply mx-auto;
              }
              span {
                @apply mx-auto;
              }
            }

            img {
              @apply w-1/2;
            }

            span {
              @apply w-3/5;
            }
          }
        }

        h3 {
          @apply mt-48;
          font-size: 54px;
        }

        .partner-badges {
          .partner-badge {
            @apply w-1/5;
          }
        }
      }
    }
  }

  /////////////////////////////////////////////

  @media screen and (min-width: 1200px) {
    .container {
      .certifications-body {
        .location-badges {
          .location-badge {
            img {
              @apply w-1/3;
            }

            span {
              @apply w-2/5;
              font-size: 22px;
            }
          }
        }
      }
    }
  }

  /////////////////////////////////////////////

  @media screen and (min-width: 1500px) {
    .container {
      .certifications-body {
        .location-badges {
          .location-badge {
            @apply w-1/12;

            img {
              @apply w-full;
            }

            span {
              @apply w-full;
              font-size: 24px;
            }
          }
        }
      }
    }
  }
}
</style>
