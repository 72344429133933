<template>
  <div id="certificationsPage">
    <CertificationsHeroContainer
      v-if="certificationsHero"
      :certifications-hero="certificationsHero"
      @loaded="toggleShowPageTrue"
    />
  </div>
</template>

<script>
import api from '@/api'
import CertificationsHeroContainer from '@/components/Hero/CertificationsHeroContainer'
import viewMixin from '@/mixins/viewMixin'
import apiHelper from '@/api/helpers/helpers'

export default {
  name: 'Certifications',
  components: { CertificationsHeroContainer },
  mixins: [viewMixin],
  data() {
    return {
      certificationsHero: null
    }
  },
  mounted() {
    this.loadCertificationLocationsPage()
  },
  methods: {
    loadCertificationLocationsPage() {
      this.setCertificationsData(apiHelper.readStoredResponse('certifications'))

      api.certifications.loadCertificationsPage().then(response => {
        this.setCertificationsData(response)
      })
    },
    setCertificationsData(response) {
      if (!response || !response.entry) return

      let certificationsHero = response.entry.certificationsHero[0]
      certificationsHero.title = response.entry.title

      this.certificationsHero = certificationsHero
    }
  }
}
</script>
